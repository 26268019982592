:root {
  --clr-bg: #f5f8fa;
  --clr-primary: #1675e0;
  --clr-primary-light: #fef9f5;
  --clr-primary-light-02: #d6c5c8;
  --clr-primary-dark: #423a38;
  --clr-secondary: #fef9f5;
  --clr-transparent: transparent;
  --clr-white-01: #ffffff;
  --clr-black-01: #000000;
  --clr-grey-01: #433b39;
  --clr-grey-02: #8e8988;
  --clr-grey-03: #cacaca;
  --clr-agborder: #f5d9d5;
  --clr-disable-icon: #ccc;
  --top-nav-height: 6.25rem;
  --aside-width: 20%;
  --main-width: 80%;
}

::selection {
  background-color: var(--clr-primary);
  color: var(--clr-white-01);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Font Families */
/* Gilroy */
@font-face {
  font-family: "Gilroy-Heavy";
  src: local("Gilroy-Heavy"),
    url(./assets/fonts/Gilroy/Gilroy-Heavy.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Bold";
  src: local("Gilroy-Bold"),
    url(./assets/fonts/Gilroy/Gilroy-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Medium";
  src: local("Gilroy-Medium"),
    url(./assets/fonts/Gilroy/Gilroy-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Regular";
  src: local("Gilroy-Regular"),
    url(./assets/fonts/Gilroy/Gilroy-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy-Light"),
    url(./assets/fonts/Gilroy/Gilroy-Light.ttf) format("truetype");
}

/* Roboto */
@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url(./assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Thin";
  src: local("Roboto-Thin"),
    url(./assets/fonts/Roboto/Roboto-Thin.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-ThinItalic";
  src: local("Roboto-ThinItalic"),
    url(./assets/fonts/Roboto/Roboto-ThinItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-MediumItalic";
  src: local("Roboto-MediumItalic"),
    url(./assets/fonts/Roboto/Roboto-MediumItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Medium";
  src: local("Roboto-Medium"),
    url(./assets/fonts/Roboto/Roboto-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-LightItalic";
  src: local("Roboto-LightItalic"),
    url(./assets/fonts/Roboto/Roboto-LightItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Light";
  src: local("Roboto-Light"),
    url(./assets/fonts/Roboto/Roboto-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Italic";
  src: local("Roboto-Italic"),
    url(./assets/fonts/Roboto/Roboto-Italic.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-BoldItalic";
  src: local("Roboto-BoldItalic"),
    url(./assets/fonts/Roboto/Roboto-BoldItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto-Bold"),
    url(./assets/fonts/Roboto/Roboto-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-BlackItalic";
  src: local("Roboto-BlackItalic"),
    url(./assets/fonts/Roboto/Roboto-BlackItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Black";
  src: local("Roboto-Black"),
    url(./assets/fonts/Roboto/Roboto-Black.ttf) format("truetype");
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: "Gilroy-Regular", "Roboto-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 1200px) {
  html,
  body {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  html,
  body {
    font-size: 12px;
  }
}
