.rs-content {
  padding: 0;
  width: 100%;
}

.hero-carousel-container {
  position: relative;
  height: 85vh;
  overflow: hidden;
  border-bottom-left-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

.custom-slider {
  .rs-carousel-item img {
    border-radius: 10px;
    object-fit: cover;
  }
}

.review-carousel-container {
  background-color: #ffffff;
  padding: 40px 0;
}

.review-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 0;
}

.reviews-stars .rs-rate-character {
  display: flex;
  justify-content: center;
  height: 1rem;
}

.user-name {
  font-size: 0.9rem;
  color: #555;
}

.rs-carousel-shape-dot .rs-carousel-label {
  background-color: #979797;
}

.review-carousel-container .rs-carousel {
  background-color: unset;
}

.review-user-info {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-details {
  text-align: center;
}

.user-name {
  font-size: 1rem;
  font-weight: bold;
}

.review-date {
  font-size: 0.75rem;
  color: #999;
}

.rs-carousel {
  height: 100% !important;
}

.custom-slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-img-container {
  background-image: url("./../../../assets/images/PVTB4154.JPG");
  width: 100%;
  height: 85vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom-left-radius: 5rem;
  border-bottom-right-radius: 5rem;
  display: flex;
  justify-content: center;
}

.hero-img-container2 {
  background-image: url("./../../../assets/images/ayurkaya-aloevera-soap-1.JPG");
  width: 100%;
  height: 85vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom-left-radius: 5rem;
  border-bottom-right-radius: 5rem;
  display: flex;
  justify-content: center;
}

.hero-img-container .hero,
.hero-img-container2 .hero {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-img-container .rs-container,
.hero-img-container2 .rs-container {
  display: block;
  flex: none;
}

.hero-text {
  margin-top: 2rem;
  display: flex;
  align-items: center;
}

.hero-main-text {
  font-size: 4rem;
  line-height: 5.6rem;
  padding: 1.25rem 0;
}

.hero-text .shop-button {
  background-color: var(--clr-secondary);
  border-radius: 2rem;
  padding: 0.625rem 1.875rem;
  font-size: 1.1rem;
}

.bartop {
  width: "100%";
  background-color: "white";
  padding: "10px";
}

/* Products */

.review-carousel-container .review-carousel .rs-carousel-slider-after,
.rs-carousel-slider-item {
  background-color: #ecf8e7;
}

.rev-con {
  margin-top: 50px;
}

.rev-con .featured-products {
  padding-bottom: 30px;
}

.products {
  padding: 3.125rem 0;
}

.products .product-subtext {
  color: grey;
  margin-top: 1rem;
}

.featured-head {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
}

.featured-products {
  display: flex;
  justify-content: center;
}

.reviews-text {
  position: relative;
  text-align: justify;
}

.featured-products .review-carousel-container,
.user-layout-container .rev-con {
  background-color: #ecf8e7 !important;
}

@media (max-width: 1200px) {
  .hero-img-container,
  .hero-img-container2 {
    height: 85vh;
    border-bottom-left-radius: 3.5rem;
    border-bottom-right-radius: 3.5rem;
  }
  .hero-main-text {
    font-size: 3rem;
    line-height: 4.5rem;
    padding: 1.125rem 0;
  }

  .hero-carousel-container {
    height: 75vh;
  }
}
@media (max-width: 768px) {
  .hero-carousel-container {
    height: 60vh;
  }
  .hero-img-container,
  .hero-img-container2 {
    height: 75vh;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    padding: 0 1.25rem;
  }
  .hero .hero-text {
    margin-left: 0;
  }
  .hero-main-text {
    font-size: 2rem;
    line-height: 3rem;
    padding: 1.875rem 0;
  }
}

@media (min-width: 1250px) {
  .hero-text .hero-mar {
    margin-left: 7.5rem;
  }
}
