.invoice-details-page {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.invoice-details-head {
  text-align: left;
  font-size: 16px;
  color: #928f8f;
}
.invoice-details-prod {
  font-size: 16px;
}

.invoice-total-summary {
  margin-top: 20px;
}

.invoice-address p,
.invoice-details-prod p {
  margin: 0;
}

.invoice-prod-list .invoice-prod-image {
  border-radius: 4px;
  object-fit: cover !important;
}

.flex-id {
  display: flex;
  justify-content: space-between;
}

.message-details-container .rs-content {
  background-color: #fff;
}

.mt-10 {
  margin-top: 0.625rem;
}

.mt-20 {
  margin-top: 1.25rem;
}

.text-right {
  display: flex;
  justify-content: end;
}

.invoice-details-head.grand,
.invoice-details-prod.grand {
  font-size: 20px;
  margin-left: -10px;
  font-weight: 600;
}

.dets-i {
  margin: 1rem 0;
}

.dets-i .rs-row {
  margin: 0;
}

.d-b-btn {
  margin: 0 0 1rem 0;
}

@media (max-width: 768px) {
  .dets-2i {
    margin: 1rem 0;
  }
}
