.check-container {
  margin: auto;
  float: none;
}

.checkout {
  padding: 5rem;
  background-color: var(--clr-bg);
}

.checkout-container {
  background-color: #ffffff;
  padding: 4.375rem;
  border-radius: 0.625rem;
}

.check-container .checkout-steps {
  margin: 1.25rem 0;
}

.checkout-cart .rs-panel-body {
  padding: 0;
  margin: 1.25rem 0;
}

.checkout-cart .checkout-cart-title {
  margin-bottom: 1.875rem;
}

.checkout-cart .checkout-item {
  margin-bottom: 1.25rem;
}

.checkout-btn {
  margin-top: 1.875rem;
}

.billing-title {
  margin: 1.25rem 0;
  font-weight: 600;
}

.user-note {
  margin: 2.5rem 0;
}

.check-container .addres-block {
  display: flex;
}

.qr-container {
  padding: 20px;
  margin-top: 20px;
}

.text-danger{
  color: red;
  

}


.rs-radio-checked .rs-radio-inner:after {
  opacity: 1 !important;
 
  transform: scale(0.9) !important;
}

.rs-radio-checker{
  padding-left: 40px !important;
}

.rs-radio-control {
  left: 14px !important;
}
/* .rs-radio-group>.rs-radio {
  margin-left: -7px !important;
}

.rs-radio-checked .rs-radio-inner:after {
   transform: scale(0.9) !important;
} */

@media (max-width: 768px) {
  .checkout {
    padding: 2rem;
  }

  .checkout-container {
    padding: 2rem;
  }
}
