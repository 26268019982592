.review {
  margin: 1rem auto;
}

.review .bg {
  border-radius: 0.625rem;
}

.review .product-container {
  padding: 0.625rem;
  border-radius: 0.625rem;
}

@media (max-width: 768px) {
  .reviews-title {
    display: block;
  }

  .reviews-title .rating {
    width: fit-content;
  }

  .review .bg {
    padding: 0rem;
  }
}
