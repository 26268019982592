.cate-cot {
  margin: 0.8rem 0;
}

.cate-box {
  padding: 1.25rem;
  background-color: #fff;
  border-radius: 0.625rem;
}

.active-check {
  margin-right: 0.625rem;
}
