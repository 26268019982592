:root {
  /* Primary Colors */
  --clr--primary: #75c32c;
  --clr--primary-light: #75c32c;
  --clr-secondary: #fc5f5f;

  --clr-white: #ffffff;

  /* Additional Shades */
  --clr-border-light: #efefef;
  --clr-border-dark: #4e5768;
  --clr-text-light: #5d6167;
  --clr-text-dark: #2b2f38;
  --clr-text-hover: #75c32c;
  --clr-text-hover-60: #75c32c47;
  --clr-text-active: #fc5f5f;

  --clr-bg: #f9f9f9;
}

html {
  font-size: 16px !important;
}

body {
  margin: 0;
  font-family: "Gilroy-Regular", "Roboto-Regular" !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-size: 16px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace !important;
}

::selection {
  background-color: var(--clr--primary);
  color: var(--clr-white);
}

.user-layout-container {
  --clr--primary: #75c32c;
  --clr-secondary: #fc5f5f;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.header-not-sticky {
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 0.625rem;
  transition: background-color 0.3s ease;
}

.header-sticky {
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  position: sticky;
  position: -webkit-sticky;
  background-color: white;
}
.back-primary {
  border: 1px solid var(--clr--primary) !important;
  color: var(--clr--primary) !important;
  box-shadow: none !important;
}

.user-layout-container .image-gallery-icon:hover {
  color: var(--clr--primary);
}

.prod-hover:hover {
  color: var(--clr--primary) !important;
}
.prod-color {
  color: #75c32c !important;
}

.user-layout-container a:active,
.user-layout-container a:focus {
  color: #75c32c !important;
}

.btn-green {
  background-color: #75c32c !important;
}

.btn-green:hover {
  background-color: #65bb10 !important;
  color: white !important;
}

.user-layout-container .rs-input:hover,
.rev-form .rs-input:hover {
  border-color: #75c32c !important;
}

.user-layout-container .image-gallery-thumbnail {
  border: 3px solid transparent;
}

.user-layout-container .image-gallery-thumbnail.active,
.user-layout-container .image-gallery-thumbnail:focus,
.user-layout-container .image-gallery-thumbnail:hover {
  border: 3px solid var(--clr--primary);
}

.user-layout-container .rs-input:focus,
.rev-form .rs-input:focus {
  border-color: #75c32c !important;
  outline: 3px solid var(--clr-text-hover-60) !important;
}

.user-layout-container
  .rs-picker-default:not(.rs-picker-disabled)
  .rs-picker-toggle:focus {
  border-color: #75c32c;
  outline: 3px solid var(--clr-text-hover-60);
}

.user-layout-container .rs-radio.rs-radio-checked .rs-radio-inner:before {
  background-color: #75c32c;
  border-color: #75c32c;
}

.rs-modal-body .rs-input-group:not(.rs-input-group-disabled):hover,
.user-layout-container .rs-input-group:not(.rs-input-group-disabled):hover {
  border-color: #75c32c;
}

.rs-modal-body .rs-input-group:focus-within,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus {
  outline: 3px solid var(--clr-text-hover-60);
  border-color: #75c32c;
}

.rs-modal-body .rs-input-group:focus-within,
.user-layout-container
  .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus {
  outline: 3px solid var(--clr-text-hover-60);
  border-color: #75c32c;
}

.user-layout-container
  .rs-picker-default:not(.rs-picker-disabled)
  .rs-picker-toggle-active {
  outline: 3px solid var(--clr-text-hover-60);
  border-color: #75c32c;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-active {
  background-color: #8a8a8a1a !important;
}

.rs-picker-select-menu-item:hover {
  background-color: #84848434 !important;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
  color: #434343 !important;
}

.rs-picker-select-menu-item:focus,
.rs-picker-select-menu-item:hover {
  color: #535353 !important;
}

.user-layout-container .rs-picker-has-value .rs-btn .rs-picker-toggle-value {
  color: #75c32c !important;
}

@font-face {
  font-family: "Gilroy-Heavy";
  src: local("Gilroy-Heavy"),
    url(../assets/fonts/Gilroy/Gilroy-Heavy.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Bold";
  src: local("Gilroy-Bold"),
    url(../assets/fonts/Gilroy/Gilroy-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Medium";
  src: local("Gilroy-Medium"),
    url(../assets/fonts/Gilroy/Gilroy-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Regular";
  src: local("Gilroy-Regular"),
    url(../assets/fonts/Gilroy/Gilroy-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy-Light"),
    url(../assets/fonts/Gilroy/Gilroy-Light.ttf) format("truetype");
}

/* Roboto */
@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url(../assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Thin";
  src: local("Roboto-Thin"),
    url(../assets/fonts/Roboto/Roboto-Thin.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-ThinItalic";
  src: local("Roboto-ThinItalic"),
    url(../assets/fonts/Roboto/Roboto-ThinItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-MediumItalic";
  src: local("Roboto-MediumItalic"),
    url(../assets/fonts/Roboto/Roboto-MediumItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Medium";
  src: local("Roboto-Medium"),
    url(../assets/fonts/Roboto/Roboto-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-LightItalic";
  src: local("Roboto-LightItalic"),
    url(../assets/fonts/Roboto/Roboto-LightItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Light";
  src: local("Roboto-Light"),
    url(../assets/fonts/Roboto/Roboto-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Italic";
  src: local("Roboto-Italic"),
    url(../assets/fonts/Roboto/Roboto-Italic.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-BoldItalic";
  src: local("Roboto-BoldItalic"),
    url(../assets/fonts/Roboto/Roboto-BoldItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto-Bold"),
    url(../assets/fonts/Roboto/Roboto-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-BlackItalic";
  src: local("Roboto-BlackItalic"),
    url(../assets/fonts/Roboto/Roboto-BlackItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Black";
  src: local("Roboto-Black"),
    url(../assets/fonts/Roboto/Roboto-Black.ttf) format("truetype");
}

@media (max-width: 1200px) {
  html,
  body {
    font-size: 14px !important;
  }
}

@media (max-width: 768px) {
  html,
  body {
    font-size: 12px !important;
  }
}
