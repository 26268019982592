.user-layout-container .product-bg {
  background-color: var(--clr-bg);
}

.product {
  margin: 1rem auto;
}

.product .rs-row {
  padding: 0rem 5.625rem 5.625rem 5.625rem;
  background-color: #fff;
  border-radius: 1.25rem;
}

.mt-50 {
  margin-top: 20px;
  margin-bottom: 30px;
}

.product-container {
  display: flex;
  align-items: flex-start;
}

.image-container {
  width: 100%;
  max-width: 400px; /* Adjust according to your design */
  height: 400px; /* Set the desired height for the image container */
  margin: 0 auto;
  text-align: center;
}

.image-container .image-gallery-slides .image-gallery-slide {
  max-height: 400px;
}

.image-container .image-gallery .image-gallery-content.fullscreen {
  height: 100vh !important;
}

.image-container
  .image-gallery-slides
  .image-gallery-slide
  .image-gallery-image {
  height: 100%;
  width: 100%;
}

.categories-section select {
  padding: 0.625rem;
  margin-top: 0.625rem;
  border-radius: 0.313rem;
}

.image-section {
  flex: 1;
  position: relative;
}

.product-image {
  width: 100%;
  border-radius: 0.5rem;
}

.details-section {
  flex: 2;
  padding-left: 1.25rem;
}

.category {
  font-size: 0.875rem;
  color: #888;
}

.product-name {
  font-size: 2.25rem;
  font-weight: bold;
  margin: 0.625rem 0;
}

.product-name1 {
  font-size: 2.25rem;
  font-weight: bold;
  margin: 0.625rem 0;
}

.fw-10 {
  font-weight: bold;
}

.product-name1:hover {
  color: var(--clr--primary);
}

.user-layout-container .description .rs-nav-item.rs-nav-item-active {
  color: var(--clr--primary) !important;
}

.user-layout-container .rs-nav-subtle .rs-nav-item:hover {
  color: var(--clr--primary);
}

.user-layout-container
  .rs-picker-default:not(.rs-picker-disabled)
  .rs-picker-toggle:hover {
  border-color: var(--clr--primary);
}

.user-layout-container
  .description
  .rs-nav-subtle
  .rs-nav-item.rs-nav-item-active:before {
  background-color: var(--clr--primary);
}

.product-list-picker {
  width: 200px;
}

.price {
  font-size: 1.125rem;
}

.description {
  margin: 2.5rem 0;
  color: #555;
}

.description-head {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 3rem;
}

.add-to-cart {
  display: flex;
  align-items: center;
  margin: 1.25rem 0;
  margin-top: 1.875rem;
}

.product .add-to-cart-button {
  color: white;
  margin-left: 0.625rem;
  width: 100%;
  border-radius: 1.25rem;
  font-weight: bold;
}

.category-footer {
  margin-top: 1.25rem;
  font-size: 0.875rem;
  color: #888;
}

.bg {
  background-color: #fff;
}

/* Product List */

.shop-head {
  font-size: 3rem;
}

.product-page-container {
  padding: 4.375rem 4.375rem;
  background-color: #f9f9f9;
}

.categories-section {
  background-color: white;
  padding: 1.25rem;
  margin-bottom: 1.25rem;
  border-radius: 0.5rem;
}

h4 {
  margin-bottom: 1.25rem;
  font-size: 1.125rem;
  color: #333;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 1rem;
}

.deal {
  position: relative;
  text-align: center;
}

.deal img {
  width: 100%;
  border-radius: 0.5rem;
}

.sale-badge {
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  background-color: #ff5a5f;
  color: white;
  padding: 0.3rem 0.625rem;
  border-radius: 1.25rem;
}

.content {
  flex: 1;
  background-color: white;
  padding: 1.25rem;
  border-radius: 0.5rem;
}

.content .product-grid {
  padding: 1.25rem 5rem;
}

.reviews-controls {
  display: flex;
  justify-content: space-between;
}

.sort-reviews-select {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.313rem;
  background-color: white;
  font-size: 0.875rem;
  cursor: pointer;
}

.header {
  background-color: var(--clr-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h2 {
  font-size: 30.5rem;
  font-weight: bold;
  margin: 0;
}

.results-sorting {
  display: flex;
  align-items: center;
}

.results-sorting span {
  margin-right: 1.25rem;
  font-size: 1rem;
  color: #555;
}

.image-container {
  width: 100%;
  height: 25rem;
  overflow: hidden;
}

.image-gallery-slide .image-gallery-image {
  object-fit: contain;
  background-color: var(--clr-bg);
  border-radius: 0.625rem;
  width: 100%;
}

.rs-btn-ghost.rs-btn {
  margin: 0 0.5rem;
}

.image-container
  .image-gallery-content
  .image-gallery-slide
  .image-gallery-image {
  border-radius: 0.625rem;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-bg {
  padding: 1.25rem 1.25rem 0.5rem 1.25rem;
}

.product-bbg {
  padding: 1.1rem 0;
}

.product-container {
  display: flex;
  gap: 1.25rem;
}

.add-to-cart {
  display: flex;
  gap: 0.625rem;
}

.description-content,
.reviews-section {
  padding: 1.25rem;
}

.review-form {
  margin-bottom: 1.25rem;
}

.reviews-list {
  margin-top: 1.25rem;
}

.product-grid {
  display: flex;
  gap: 1.25rem;
  flex-wrap: wrap;
}

.product-grid .product-row {
  width: 100%;
}

.range-slider {
  margin: 1.25rem 0;
}

.price {
  display: flex;
  margin: 0.625rem 0;
}

.rrrat .rs-rate {
  color: #2196f3;
}

.price .detail-title {
  font-weight: 400; /* Subtle title */
  color: #666; /* Soft gray color */
  margin-right: 0.5rem; /* Add space between title and value */
}

.price .detail-value {
  font-weight: 600; /* More prominent value */
  color: #333; /* Darker color for the value */
}

.price .rate-stars {
  display: flex;
  height: 0.625rem;
  margin-top: 1.125rem;
  align-items: center;
  margin-left: 0.625rem;
  margin-right: 0px;
}

.product-rating {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #333;
}

.rating-text {
  margin-right: 0.5rem;
  font-weight: bold;
  color: #444;
}

.flex-3 {
  display: flex;
  justify-content: space-between;
}

.review-count-text {
  color: var(--clr--primary) !important;
}

.review-count-text a {
  text-decoration: none;
  color: var(--clr--primary) !important;
  font-weight: bold;
}

.review-count-text a:hover {
  text-decoration: underline;
  color: var(--clr--primary) !important;
}

.flex-01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-to-cart .rs-input-number {
  width: 70px;
}

.backprimary {
  background-color: white;
}

.delete-item-btn {
  margin-top: -5px;
}

.categories-section .category-filter:hover {
  color: var(--clr--primary);
}

@media (max-width: 1200px) {
  .content .product-grid {
    padding: 1rem 1.5rem;
  }

  .add-to-cart .rs-input-number {
    width: 55px;
  }

  .product .product-container {
    display: block;
  }

  .product .bg {
    padding: 2rem !important;
  }

  .product-page-container {
    padding: 2.375rem;
    background-color: #f9f9f9;
  }
}
@media (max-width: 768px) {
  .content .product-grid {
    padding: 1rem 1rem;
  }

  .product-grid .product-row {
    width: unset;
  }

  .retuurm {
    font-size: 12px;
  }

  .content {
    flex: 1;
    background-color: white;
    padding: 0;
    border-radius: 0.5rem;
  }

  .reviews-controls {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .product-rating {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .price .rate-stars {
    margin-top: 0;
    margin-left: 0;
  }

  .price .rate-stars .rs-rate-character {
    display: flex;
    align-items: center;
  }

  .rating-text {
    margin-bottom: 5px;
  }

  .review-count-text {
    margin-top: 10px;
  }

  .rs-picker-select-menu.rs-picker-popup {
    top: auto;
  }

  .product .product-container {
    display: block;
  }

  .product .bg {
    padding: 1rem !important;
  }

  .product-page-container {
    padding: 1.375rem;
    background-color: #f9f9f9;
  }

  .product-gallery-container
    .image-gallery-thumbnails
    .image-gallery-thumbnails-container {
    width: 33%;
  }

  .reviews-card .rs-panel-body {
    padding: 20px 0;
  }

  .reviews-description.collapsed {
    max-height: 145px !important;
  }

  .reviews-title h4 {
    font-size: 18px;
  }

  .reviews-header {
    display: block;
  }
}

@media (max-width: 380px) {
  .user-layout-container .rs-navbar-brand {
    padding: 0px;
  }
}
