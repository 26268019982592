.shopping-cart-container {
  padding: 1.25rem;
  background-color: var(--clr-bg);
}

.shopping-cart-panel,
.checkout-panel {
  background-color: white;
  padding: 1.25rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.1);
}

.shopping-cart-panel .rs-row .rs-col {
  float: none;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 0;
}

.rs-table-cell-content .inpooooo {
  width: 4.5rem;
  margin-top: -8px;
}

.gdavsx img {
  margin-top: -10px;
}

.cart-image {
  width: 6rem;
  height: 6rem;
  object-fit: cover;
  border-radius: 0.5rem;
}

.cart-details {
  flex: 1;
  margin-left: 0.938rem;
}

.cart-pricing {
  text-align: right;
}

.total-price {
  color: var(--clr--primary);
  font-weight: bold;
}

.cart-summary {
  text-align: right;
  font-size: 1rem;
}

.cart-summary h4 {
  font-size: 1.25rem;
  color: orange;
  margin-top: 0.625rem;
}

.cart-prod-name {
  color: var(--clr-text-dark);
}

.cart-prod-name:hover {
  color: var(--clr--primary);
  text-decoration: none;
}

.check-btn {
  margin-top: 1.25rem;
  padding: 0.625rem 0;
}

.input-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-quant {
  margin-top: 0.25rem !important;
  width: 5rem;
}

.prod-input {
  width: 3.5rem;
  border-radius: 0.313rem;
}

.cart-option {
  height: 5rem !important;
}

.proooo {
  margin-right: 1.25em;
}

@media (max-width: 1000px) {
  .checkout-panel {
    margin-top: 1.25rem;
  }
}
