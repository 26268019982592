.address-card {
  cursor: pointer;
  transition: all 0.3s ease;
  border: 0.125rem solid transparent;
}

.address-card-item .address-card {
  min-height: 12.5rem;
}

.address-card.selected {
  border-color: var(--clr--primary);
  background-color: #75c32c1b;
}

.address-card.selected .address-title {
  color: var(--clr--primary);
}

.address-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.address-title {
  font-weight: bold;
  font-size: 1.2rem;
}

.address-subtitle {
  font-weight: bold;
  font-size: 1rem;
}

.address-details p {
  margin: 0;
  font-size: 0.9rem;
  color: #555;
}

.address-actions {
  text-align: right;
}

.address-actions button {
  font-size: 0.85rem;
}

.rs-badge {
  font-size: 0.75rem;
}

.address-card .rs-tag-md {
  padding: 1px 6px;
}
