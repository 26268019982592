.contact-container {
  background-color: var(--clr-bg);
}

.contact-container .contact {
  float: none;
  margin: 5rem auto;
}
.contact-container .coon-tact {
  background-color: #fff;
  padding: 1.25rem !important;
  border-radius: 0.5rem;
}

.contact-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5rem;
}

.contact-details h3 {
  margin-bottom: 1.25rem;
  font-size: 1.5rem;
}

.contact-item {
  display: flex;
  align-items: start;
  margin-bottom: 1.25rem;
}

.contact-icon {
  color: #ff5a5f;
  margin-right: 0.938rem;
}

.contact-item h5 {
  margin: 0 0 0.313rem;
  font-size: 1.125rem;
  color: #333;
}

.contact-item p {
  margin: 0;
  color: #555;
}

.contact-map iframe {
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  width: 100%;
  height: 30rem;
  margin: 1rem 0;
}

.contact-form {
  background-color: #fff;
  padding: 1.25rem !important;
  border-radius: 0.5rem;
}

.message-row h3 {
  margin-bottom: 1.25rem;
  font-size: 1.5rem;
}

.rs-form-group {
  margin-bottom: 0.938rem;
}

.rs-btn-primary {
  background-color: #ff5a5f;
  border-color: #ff5a5f;
}

.rs-btn-primary:hover {
  background-color: #e14a4f;
  border-color: #e14a4f;
}

@media (max-width: 1268px) {
  .contact-container .contact {
    margin: 2rem auto;
  }

  .contact-form {
    background-color: #fff;
    padding: 0 !important;
    border-radius: 0.5rem;
  }

  .map {
    margin-top: 20px;
  }
}
