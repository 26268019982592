/* Footer.css */
.rs-footer {
  width: 100%;
}

.footer {
  background-color: #ffffff;
  padding: 5rem 0 3.125rem;
}

.footer .footer-container {
  margin: auto;
  margin-bottom: 1.875rem;
}

.footer-col {
  padding: 0 0 0.625rem;
  width: 100%;
  margin-bottom: 1.25rem;
}

.footer-logo-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 1.25rem;
}

.footer-logo {
  width: 5.6rem;
  height: 5.6rem;
}

.footer-icons {
  padding: 10;
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.foot-icon {
  padding: 0.625rem 0.625rem 0.3rem 0.625rem;
  background-color: var(--clr-secondary-2);
  border-radius: 0.3rem;
}

.foot-icon:hover {
  background-color: var(--clr--primary);
}

.footer-links a {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-bottom: 0.313rem;
  color: var(--clr-text-dark);
}

.footer-header {
  font-size: 1.25rem;
  color: var(--clr-text-dark);
  line-height: 1.875rem;
  font-weight: 600;
  margin-bottom: 1.25rem;
}

.foot-link a:hover {
  color: var(--clr--primary);
  text-decoration: none;
  cursor: pointer;
}

.copyright {
  padding: 1.25rem 0 0 0;
  margin: auto;
}

.copy {
  display: flex;
  justify-content: space-between;
}

.powered {
  text-align: end;
}

@media (max-width: 768px) {
  .footer-logo-col {
    align-items: start;
  }

  .copy {
    display: flex;
    flex-direction: column;
  }

  .powered {
    text-align: left;
    margin-top: 5px;
  }
}
