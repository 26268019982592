:root {
  --clr-bg: #f5f8fa;
  --clr--primary: #1675e0;
}

html {
  font-size: 16px;
}

.defaultTheme {
  --clr-primary: #b18c93;
  --clr-primary-light: #fef9f5;
  --clr-primary-dark: #423a38;
  --clr-secondary: #fef9f5;
  --clr-transparent: transparent;
  --clr-white-01: #ffffff;
  --clr-black-01: #000000;
  --clr-grey-01: #433b39;
  --clr-bg: #f5f8fa;
}

body {
  background: #f5f8fa;
}

.content-container {
  padding-left: 16.25rem;
  transition: padding 0.5s;
}
.content-container.container-full {
  padding-left: 3.75rem;
}

/* Layout styles */
.app-container {
  height: 100vh;
}
.app-container {
  background: #fff;
}
.app-container .rs-sidenav {
  flex: 1 1 auto;
  transition: none !important;
  border-top: 1px solid #e2e0e0;
  width: 100%;
}

.content-container .rs-content {
  padding: 0 1rem;
  background-color: var(--clr-bg);
}
.rs-content .rs-panel-header .title {
  font-size: 1.125rem;
}
.rs-content .rs-panel-header .rs-breadcrumb {
  margin-bottom: 0;
}
.nav-toggle {
  border-top: 1px solid #e2e0e0;
}

.app-container .rs-dropdown-item.active {
  color: blue !important;
}
.app-container .rs-dropdown-item.active-icon {
  height: 1.3em !important;
  width: 1.3em !important;
}

.brand {
  padding: 1.188rem;
  font-size: var(--fs-4);
  white-space: nowrap;
  overflow: hidden;
  font-weight: bold;
  text-transform: uppercase;
}
.brand a {
  text-decoration: none;
}

.upper {
  text-transform: uppercase;
}

/* Sidebar Styles */
.rs-sidebar {
  position: fixed;
  height: 100vh;
  z-index: 3;
  background-color: #ffffff;
}

.rs-sidenav {
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: width 0.3s ease-in-out;
}

.rs-sidenav-body {
  overflow-y: auto;
  flex: 1;
}

.rs-sidenav-header {
  color: var(--clr-white-01);
  text-align: center;
}

.rs-sidenav-item {
  font-size: var(--fs-4);
  padding: 0.75rem 1rem;
  color: var(--clr-grey-01);
  border-left: 4px solid transparent;
  transition: background-color 0.3s, border-color 0.3s;
}

.rs-sidenav-item.active {
  background-color: var(--clr-primary);
  color: var(--clr-white-01);
  border-left-color: var(--clr-primary-dark);
}

.rs-sidenav-item .rs-icon {
  font-size: 1.25rem;
  margin-right: 0.75rem;
}

.rs-sidenav-item.active-icon {
  height: 1.5em;
  width: 1.5em;
}

.nav-toggle {
  border-top: 1px solid #e2e0e0;
  background-color: var(--clr-primary-light);
}

.brand {
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}

.brand a {
  text-decoration: none;
  color: var(--clr-black-01);
}

/* .rs-content {
  padding: 1rem !important;
  background-color: var(--clr-bg);
} */

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 3.75rem !important;
  width: 3.75rem !important;
}

.panelClass {
  background-color: var(--clr-white-01);
}
