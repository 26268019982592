/* ProductCard.css */
.product-card-container .product-card .rs-panel-body {
  padding: 0;
  margin-bottom: 1.25rem;
}

.product-card .product-image-container {
  position: relative;
  height: 15rem;
  overflow: hidden; /* Prevents image from overflowing */
  width: 100%;
}
.product-card .rs-row {
  margin: 1rem 0;
}

.icons-container {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: end;
  width: 100%;
  padding: 0.625rem;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.icon-wrapper {
  background-color: #fff;
  padding: 0.625rem 0.625rem 0.313rem 0.625rem;
  border-radius: 2rem;
  box-shadow: -1px 9px 31px -3px rgba(0, 0, 0, 0.22);
}

.wishlist-icon {
  color: var(--clr-primary);
}

.cart-icon {
  color: var(--clr-text-dark);
}

.product-card-container:hover .icons-container {
  opacity: 1;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Keeps image aspect ratio intact */
  transition: transform 0.3s ease-in-out;
  object-fit: contain;
}
.product-image-container:hover .product-image {
  transform: scale(1.1); /* Zooms in the image */
}

.product-info {
  margin-top: 0.93rem;
}

.product-category {
  font-size: 0.9rem;
  color: gray;
}

.product-info .product-names {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 0.3rem;
  color: var(--clr-text-dark);
}

.product-rating {
  margin: 0.125rem 0 0.3rem;
}

.product-price {
  color: var(--clr-text-light);
  font-weight: 900;
  margin-right: 0.5rem;
}

.original-price {
  text-decoration: line-through;
  color: var(--clr-text-light);
  opacity: 50%;
  font-weight: 900;
  margin-right: 0.5rem;
}

.discounted-price {
  color: var(--clr-text-light);
  font-weight: 900;
  font-weight: bold;
}
