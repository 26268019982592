.about-bg {
  background-color: var(--clr-bg);
}

.about-bg .about-container {
  float: none;
  margin: auto;
}

.about-img-container {
  width: 100%;
  height: 45vh;
  background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.706) 80%
    ),
    url("https://websitedemos.net/plant-store-02/wp-content/uploads/sites/410/2020/07/about-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom-left-radius: 5rem;
  border-bottom-right-radius: 5rem;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  padding: 6rem 9rem;
}

.about-main-text {
  font-size: 4rem;
  line-height: 5.6rem;
  padding: 1.25rem 0;
}

.about-content-container {
  padding: 3.125rem 0.938rem;
  border-radius: 0.625rem;
}

.about-content-row {
  display: flex;
  flex-wrap: wrap;
  margin: 8rem;
}

.about-text-col .about-mains-text {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2rem;
  padding: 0;
}

.about-text-col .about-text {
  font-size: 1rem;
  line-height: 1.7rem;
}

.about-button-row {
  text-align: center;
  margin-top: 1.25rem;
}

.about-company-container {
  padding: 3.125rem 0.938rem;
  text-align: center;
  background-color: #f9f9f9;
}

.about-company .about-company-title {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1.25rem;
}

.about-company .about-company-text {
  float: none;
  margin: auto;
  font-size: 1rem;
  line-height: 1.7rem;
}

.about-company-text .company-text {
  text-align: center;
}

.about-company-description {
  font-size: 1rem;
  color: #777;
}

.company-image {
  width: auto;
  height: 50rem;
  border-radius: 0.625rem;
  object-fit: cover;
  object-position: center;
  margin: 2rem 0;
}

.company-lorem {
  margin: 5rem 0;
}

.company-lorem .lorem {
  line-height: 1.8rem;
}

@media (max-width: 1200px) {
  .about-main-text {
    font-size: 3rem;
    line-height: 5.6rem;
    padding: 1.25rem 0;
  }
  .about-img-container {
    padding: 6rem 8rem;
  }
}
@media (max-width: 800px) {
  .about-main-text {
    font-size: 2rem;
    line-height: 5.6rem;
    padding: 1.25rem 0;
  }
  .about-img-container {
    padding: 6rem 7rem;
  }
}
