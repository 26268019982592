.product-gallery-container {
  width: 100%;
  height: 100%;
}

.image-gallery-slide img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.image-gallery-slide,
.image-gallery-thumbnail {
  width: 100%;
  height: 100%;
}

.image-gallery-thumbnail img {
  object-fit: cover;
  height: 5.3rem;
}

.image-gallery-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-gallery-container
  .image-gallery
  .image-gallery-content
  .image-gallery-slide
  .image-gallery-image {
  height: 400px;
}

.product-gallery-container
  .image-gallery
  .fullscreen
  .image-gallery-slide
  .image-gallery-image {
  height: 100vh;
}
