.dashbaord-container {
  overflow: hidden;
}

.trend-box .title {
  font-weight: 600;
  margin: 0;
  color: var(--clr-primary-light);
}

.value {
  font-size: 2rem;
  color: var(--clr-primary-light);
  text-align: end;
  font-weight: 500;
}

.trend-box .rs-panel-body {
  padding: 1rem;
}

.panel-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.trend-box-container {
  display: flex;
  justify-content: space-between;
}

.trend-box-container .rs-panel {
  overflow: hidden;
  border-radius: 0.8rem;
  background-color: #ffffff;
  margin-bottom: 1.6rem;
  box-sizing: border-box;
}

.charts-container {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: space-between;
}

.bg-gradient-red {
  background: linear-gradient(87deg, #f5365c 0, #f56036 100%);
}

.bg-gradient-teal {
  background: linear-gradient(87deg, #38ef7d 0%, #11998e 100%);
}

.bg-gradient-yellow {
  background: linear-gradient(87deg, #fbb034 0%, #ffdd00 100%);
}

.bg-gradient-orange-red {
  background: linear-gradient(87deg, #fb6514 0%, #f29306 100%);
}

.bg-gradient-green {
  background: linear-gradient(87deg, #28a745 0%, #1e7e34 100%);
}

.bg-gradient-blue {
  background: linear-gradient(87deg, #11cdef 0, #1171ef 100%);
}

.bg-gradient-lavender {
  background: linear-gradient(87deg, #8080f1 0%, #9d47fa 100%);
}

.bg-gradient-purple-pink {
  background: linear-gradient(87deg, #2c3e50 0%, #4b79a1 100%);
}

.topbar .rs-picker-toggle-wrapper {
  max-width: 30rem;
}

.topbar .rs-form-vertical .rs-form-group .rs-input-group {
  width: 200px;
}

.topbar-container {
  margin: 1rem 0 1.5rem 0;
  position: sticky;
  z-index: 5;
  top: 0;
}

.topbar {
  position: sticky !important;
  top: 0;
  z-index: 5;
  background-color: #ffffff;
  border-radius: 0.5rem;
}

.hero-carousel-container .rs-carousel-label-wrapper input:checked ~ label {
  background-color: #f5365c;
}

.review-carousel-container .rs-carousel-label-wrapper input:checked ~ label {
  background-color: var(--clr--primary);
}

.butn-grp {
  margin-right: 1rem;
}

.mr-10 {
  margin-right: 10px;
}

.bar-chart,
.pie-chart {
  background-color: #ffffff;
  border-radius: 0.8rem;
  margin-bottom: 1.25rem;
}

.form-flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.25rem;
}

.topbar .rs-form-group {
  margin-bottom: 0 !important;
}

.flexbox {
  display: flex;
  justify-content: space-between;
  padding: 0.625rem;
}
.flexbox1 {
  display: flex;
  justify-content: space-between;
  padding: 01.25rem;
}

/* PieChart.css */
