.user-layout-container .forgot-bg,
.user-layout-container .create-password-bg,
.user-layout-container .change-container {
  background-color: var(--clr-bg);
}

.create-password-bg .create-password-container {
  background-color: var(--clr-white);
  border-radius: 0.625rem;
  margin: 4.375rem auto;
  float: none;
}

.create-password-form {
  padding: 1.25rem;
}

.rs-row .password-container {
  float: none;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.change-password-container {
  margin: 3.125rem auto;
  padding: 1.25rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.1);
}

.change-password-container h3 {
  text-align: center;
  margin-bottom: 1.25rem;
}

.rs-row .forgot-container {
  float: none;
  margin: auto;
}

.forgot-password-container {
  margin: 3.125rem auto;
  padding: 1.25rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.1);
}

.forgot-btn-text {
  font-size: 1rem;
  font-weight: 600;
}

.user0name {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0 0 1rem 0;
}
