.review-card {
  margin-bottom: 1.25rem;
}

.review-card h4 {
  font-size: 1.25rem;
  font-weight: bold;
}

.review-card .rs-panel-body {
  padding: 0;
}

.review-rating {
  margin-bottom: 0.625rem;
}

.review-date {
  font-size: 0.875rem;
  color: #888;
  margin-bottom: 0.625rem;
}

.review-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

.review-rating {
  color: #f5a623 !important;
}

.reviews-section {
  width: 100%;
}

.review-star-size {
  height: 1.563rem;
}

.reviews-card {
  border-radius: 8px;
  margin-bottom: 16px;
  font-family: Arial, sans-serif;
}

.rs-carousel-content .rs-carousel-slider .rs-carousel-slider-item {
  padding: 1rem;
}

.reviews-description {
  display: block;
  overflow: hidden;
  visibility: visible;
  max-height: none;
}

.reviews-description.collapsed {
  max-height: 7.4em;
}

.reviews-description.expanded {
  max-height: none;
}

.re .reviews-date {
  color: grey;
  font-size: 12px;
  margin-bottom: 8px;
}

.reviews-text {
  margin: 12px 0;
  font-size: 14px;
  color: #333;
}

.reviews-title .rating {
  padding: 0.2rem 0.425rem;
  margin-right: 0.625rem;
  color: white;
  border-radius: 0.225rem;
  background-color: var(--clr--primary);
}

h4 {
  margin: 0;
  font-size: 18px;
}

h6 {
  font-size: 16px;
  color: #555;
}

.reviews-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reviews-title {
  display: flex;
  align-items: center;
}

.reviews-title h4 {
  margin-right: 8px;
}

.reviews-title .rating {
  display: flex;
  align-items: center;
}

.reviews-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  color: grey;
}

.fa-trash,
.fa-pen {
  cursor: pointer;
  margin-right: 8px;
  color: darkred;
}

.reviewer-info {
  font-size: 12px;
}

.certified-buyer {
  font-size: 12px;
  font-style: italic;
}

.mb-flex {
  display: flex;
}

.reviews-actions {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 768px) {
  .mb-flex h4 {
    line-height: unset;
  }
}
