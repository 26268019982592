/* TermsAndConditions.css */
.rs-content .terms-container {
  padding: 1.25rem;
  background-color: var(--clr-bg);
}

.terms-container .terms {
  background-color: #fff;
  border-radius: 0.625rem;
}

.terms-content {
  margin-top: 1.25rem;
}

.terms-content h4 {
  margin-top: 1.25rem;
  font-size: 1.2em;
}

.terms-content p {
  margin-bottom: 15px;
  font-size: 1em;
  line-height: 1.6;
}

.show-grid {
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) {
  .terms-content h4 {
    font-size: 1em;
  }

  .terms-content p {
    font-size: 0.9em;
  }
}
