/* User header css */

.user-layout-container .rs-header {
  padding: 0.625rem;
}

.rs-conatiner .user-navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.93rem 0;
}

.user-navbar-container {
  position: relative;
}

.user-navbar .user-navbar-container {
  float: none;
  margin: auto;
}

.header-logo {
  width: 3.125rem;
  height: 3.125rem;
}

.user-navbar-container .logo-container {
  display: flex;
  align-items: center;
}

.nav-items .rs-navbar-item {
  color: var(--clr-text-dark);
  font-weight: 600 !important;
}
.nav-items .rs-navbar-item a {
  color: var(--clr-text-dark);
  font-weight: bolder !important;
}

.nav-items .rs-navbar-item a:hover {
  color: var(--clr--primary);
  text-decoration: none;
}

.logo-text {
  color: var(--clr-text-dark);
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-weight: 600;
  margin-left: 1rem;
}

.user-navbar-container .nav-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rs-navbar-subtle .rs-navbar-item.rs-navbar-item-active {
  color: var(--clr--primary) !important;
}

.nav-items .menu-btn {
  padding: 0.625rem;
  border: 1px solid;
}

.rs-drawer-content .mobile-drawer {
  padding: 2rem 4rem;
}

.mobile-drawer .rs-nav {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  align-items: flex-start;
}

.usermenu-avatar img {
  object-fit: cover;
}

.mobile-drawer .rs-nav .rs-nav-item {
  font-size: 1.2rem;
  font-weight: 600;
}

.mobile-drawer .rs-nav .rs-nav-item:hover {
  color: var(--clr--primary);
}

.noti-divider {
  margin: 0.625rem 0 !important;
}

.notiflex {
  display: flex;
  justify-content: space-between;
}

.noti-title {
  color: grey;
  font-size: 0.875rem;
  margin-top: 0;
}

.noti-click {
  cursor: pointer;
}
.noti-click:hover {
  text-decoration: none;
}

.red-dot {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: red;
  border-radius: 50%;
  margin-right: 0.313rem;
}
.dot-dot {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  margin-right: 0.313rem;
}
.load-more-btn {
  display: flex;
  justify-content: center;
  width: 100%;
  background: transparent;
  border: none;
  color: #007bff;
  font-size: 0.875rem;
  cursor: pointer;
  margin-top: 0.625rem;
  padding: 0;
}

.rs-nav-tabs.rs-nav-horizontal .rs-nav-item.rs-nav-item {
  background-color: #ffffff;
  border: 0.05rem solid #d9d9d9;
}

.orddd .rs-nav-tabs.rs-nav-horizontal .rs-nav-item.rs-nav-item-active {
  background-color: var(--clr-primary);
  border-bottom: 0;
  color: white;
}

.rs-nav-tabs.rs-nav-horizontal .rs-nav-item.rs-nav-item-active {
  background-color: var(--clr--primary);
  border-bottom: 0;
  color: white;
}

.login-tabs .rs-nav-tabs.rs-nav-horizontal .rs-nav-item.rs-nav-item-active {
  background-color: #75c32c;
  border-bottom: 0;
  color: white;
}

.user-menu-links li a {
  color: var(--clr-text-dark);
  font-weight: 900;
}

.user-menu-links li a:hover {
  color: #75c32c;
  text-decoration: none;
}

.rs-navbar-item .login-btn:hover {
  background-color: #f7f7fa;
}

.cart-container {
  display: flex;
  gap: 0.625rem;
  align-items: center;
}

.cart-icon {
  font-size: 0.95rem;
}

.register-btns {
  display: flex;
  justify-content: space-between;
}

.cancel-btn {
  float: none;
  display: flex;
}

.submit-btn {
  widows: 100%;
}

.bell-icon .rs-navbar-item {
  padding: 0 !important;
}

.bell button {
  padding: 1.88rem 1.88rem;
  border-radius: none;
  background-color: transparent;
}

.bell svg {
  width: 1rem;
  height: 1rem;
}

.rs-modal-backdrop.rs-anim-in {
  top: 0 !important;
}

.rs-popover.rs-anim-fade {
  top: 3.43rem !important;
}

.login-btn {
  margin: 0 !important;
  padding: 0 !important;
}

.error-messaaage {
  color: red;
  font-size: 14px;
  margin: 0.5rem 0.5rem 0.5rem 0.6rem;
  text-align: left;
}

.checkbox-log {
  font-size: 20px;
  margin: 5px;
  margin-top: 3px;
}

.checkbox-log:checked {
  accent-color: var(--clr--primary);
}

.check-flex {
  display: flex;
  align-items: center;
}

.cancel-btn button {
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
}

.cancel-btn button:hover {
  background-color: #dddddd;
}

.login-btn .login {
  padding: 0.938rem !important;
}

.login-btn .rs-btn {
  padding: 1.25rem 0.75rem;
  background: unset !important;
}

.mobile-drawer .rs-nav-item.rs-nav-item-active {
  color: var(--clr-text-dark);
  border: none;
}

.mobile-drawer .rs-nav-subtle .rs-nav-item.rs-nav-item-active:before {
  background-color: transparent;
}

.aggreee {
  margin: 0.625rem 0;
}

@media (max-width: 1200px) {
  .user-layout-container .rs-header {
    padding: 0.625rem 1rem;
  }
}

@media (max-width: 760px) {
  .user-layout-container .rs-header {
    width: 100%;
    padding: 0.625rem;
  }

  /* .rs-anim-fade.rs-anim-in {
    top: 0 !important;
  } */
}
