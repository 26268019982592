.edit-profile-admin {
  margin: 1.25rem 0;
}

/* Hide the default file input */
.image-upload-input {
  display: none;
}

/* Custom file upload button styling */
.custom-file-upload {
  margin-top: 10px;
}

.custom-file-label {
  display: flex;
  justify-content: center;
  padding: 0.5rem 0.75rem;
  background-color: #3498ff;
  margin-left: 35px;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}
.custom-fil-label {
  display: flex;
  justify-content: center;
  padding: 0.5rem 0.75rem;
  background-color: #3498ff;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.custom-file-label:hover {
  background-color: #298bec;
}
