/* productsList.css */

.products-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.filters-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.938rem;
}

.image-cell {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-cell img {
  width: 2.5rem;
  height: 2.5rem;
  background: #f5f5f5;
  border-radius: 0.375rem;
  object-fit: cover;
}
/* productsList.css */

.products-header h2 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
}

.filters-row {
  margin-bottom: 1rem;
}

.title {
  margin-top: 1.875rem;
  margin-bottom: 1.25rem;
}

.product-image {
  height: "100%";
}

.mb-20 {
  margin-bottom: 1.25rem;
}

.product-panel {
  background-color: #ffffff;
  border-radius: 0.8rem;
}

.product-detail-container {
  display: flex;
  justify-content: space-between;
}

.hidden-content {
  display: none;
}

.frm-btn-grp {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  gap: 0.5rem;
}

.frm-btn-grp > * {
  margin: 0;
}

.productForm .rs-picker-toggle-value {
  color: #343434 !important;
}

.image-container .fullscreen .image-gallery-slides .image-gallery-slide {
  max-height: 100vh;
}

.prod-admin-image {
  width: 25rem;
  height: 31.25rem;
  object-fit: cover;
  object-position: center;
}

.prod-rate {
  display: flex;
  align-items: center;
  margin-top: -8px;
  gap: 5px;
}

.prod-rate .rate-starts {
  margin-top: 7px;
}

.produc-contains {
  margin: 0.8rem 0;
}

.prodd {
  padding: 1.25rem;
  border-radius: 0.625rem;
  background-color: #ffffff;
}

.image-pr .rs-form-control-label {
  margin-top: 1.25rem;
}

.certified-user {
  font-style: italic;
  font-size: 0.8rem;
}

.icon-blue {
  color: #3498ff;
}
