/* header.css */
/* .rs-header {
  position: absolute;
} */

.header {
  background-color: var(--clr-bg);
  padding: 0.625rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}

.header-title {
  font-size: 1.125rem;
  font-weight: 600;
}

.header-icons {
  display: flex;
  gap: 1.25rem;
  align-items: center;
}

.header .rs-stack {
  display: flex;
  align-items: center;
}

.italic {
  font-style: italic;
}

.user-menu-link a {
  color: #333;
  font-weight: bold;
}
.user-menu-link a:hover {
  color: var(--clr-primary);
  font-weight: bold;
}

.admin-notes .rs-stack {
  justify-content: left !important;
}

a:active,
a:focus,
a:hover {
  text-decoration: none !important;
}

.header .rs-icon-button {
  margin-right: 0.938rem;
}

.header .rs-icon-button .rs-badge {
  top: -0.313rem;
  right: -0.313rem;
}

.header .rs-avatar {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.header .rs-avatar:hover {
  transform: scale(1.1);
}

.rs-popover {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.rs-popover .rs-list-item {
  padding: 0.625rem 0.938rem;
}

.rs-navbar-item .rs-conatiner .rs-popover .rs-list-item p {
  margin: 0.313rem 0 0;
  color: #333;
}

.nav-items .rs-navbar-subtle .rs-navbar-item.rs-navbar-item-active {
  color: #ff42b3; /* Pink color */
}

.rs-popover .rs-list-item .rs-stack {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.rs-popover .rs-list-item .rs-badge {
  margin-right: 0.625rem;
  background-color: #ffc107;
}

.rs-popover .rs-button {
  width: 100%;
  background-color: #007bff;
  color: #fff;
}

.rs-popover .rs-button:hover {
  background-color: var(--clr-primary);
}

.brand-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.loadnore {
  text-align: center;
  margin-top: 1.25rem;
}
