.message-bg .messages-container {
  float: none;
  background-color: #fff;
  padding: 1.25rem;
  margin: 1.1rem auto;
  border-radius: 0.625rem;
}

.date-butns {
  margin-bottom: 0.5rem;
}

.message-bg .rs-nav-tabs.rs-nav-horizontal .rs-nav-bar {
  border: none;
}

.rs-carousel-slider-after,
.rs-carousel-slider-item {
  background-color: #ecf8e7 !important;
}

.message-detail .message-details-container {
  float: none;
  margin: 1.25rem;
  background-color: #fff;
  padding: 1.25rem;
  border-radius: 0.625rem;
}

.action-icons {
  display: flex;
  align-items: center;
  gap: 0.938rem;
}
