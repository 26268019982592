.ig-icon {
  font-size: 1.5rem;
}

.ac-icon {
  font-size: 1.25rem;
}

/* src/components/ReviewDetailsModal.css */
.review-details-modal .rs-modal-body {
  padding: 1.25rem;
  margin-top: 0;
}

.review-details-modal .rs-rate {
  color: #75c32c;
}

.details-container .rs-rate {
  color: #2196f3;
}

.flex-gap {
  display: flex;
  gap: 0.624rem;
}

.detail-item {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.detail-itemmn {
  margin: 0.625rem 0;
}

.review .bg {
  padding: 1.25rem;
}

.details-container {
  display: flex;
  flex-direction: column;
}

.detail-item {
  margin-bottom: 0.75rem;
}

.detail-key {
  margin-right: 0.5rem;
}

.detail-value-rating {
  margin-top: 0.75rem;
}

.detail-value {
  font-weight: bold;
  color: #333;
}

.filter-section {
  position: relative;
  margin-bottom: 1.25rem;
  display: flex;
  align-items: center;
}

.filter-section label {
  margin-right: 0.625rem;
  font-weight: bold;
}

.filter-section select {
  padding: 0.5rem 0.75rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  outline: none;
}

.filter-section select:focus {
  border-color: #007bff;
}
