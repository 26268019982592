.users-cont {
  margin: 1.25rem 0;
  background-color: #fff;
  padding: 1.25rem;
  border-radius: 0.625rem;
}

.search-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}

.row-padding {
  margin-bottom: 1rem; /* Adjust the value as needed */
}

.user-details-container {
  padding: 1.25rem;
}

.user-details-panel {
  border-radius: 8px;
}

.profile-image-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-info-col {
  padding: 1.25rem;
}

.user-info-details {
  background-color: #fff;
  padding: 0.938rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.addresses-col {
  padding: 0.625rem;
}

.address-item {
  background-color: #fff !important;
  padding: 0.938rem;
  margin-bottom: 10px !important;
  border-radius: 8px;
  box-shadow: none !important;
}

.address-item hr {
  border: 0;
  border-top: 1px solid #ddd;
}

.frm-btn-grp {
  text-align: center;
  margin-top: 1.25rem;
}

.nohiver:hover {
  cursor: auto !important;
}

.pagination {
  padding: 1.25rem 1.25rem 0 1.25rem;
}

.pagination-0 {
  padding: 1.25rem 1.25rem 0 1.25rem;
}

@media (max-width: 768px) {
  .pagination {
    padding: 20px 0;
  }

  .pagination .rs-pagination-group {
    display: flex;
    flex-direction: column;
    justify-content: left;
  }
}
