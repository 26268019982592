.order-container {
  background-color: #ffffff;
  border-radius: 1.25rem;
}

.order-details-container {
  margin: 1.875rem 0;
}

.orddd {
  padding: 1.25rem;
  border-radius: 0.625rem;
  background-color: #fff;
}

.orrrd {
  margin: 0.8rem 0;
}

.order-list {
  padding: 0.625rem;
  background-color: #ffffff;
  border-radius: 1.25rem;
}

.order-item {
  padding: 0.625rem 0;
}

.product-dets {
  margin-top: 1.25rem;
}

.order-steps {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.timeline-container {
  padding: 1.25rem;
}

.timeline-item {
  margin-bottom: 1.25rem;
}

.timeline-item input {
  margin-top: 0.625rem;
}

.rs-timeline-item-content .rs-input {
  width: 75% !important;
}

.custom-tabs {
  background-color: #ffffff;
  padding: 0px 0.375rem;
  border-radius: 0.313rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-search-tabs {
  background-color: #ffffff;
  padding: 0.625rem 0.625rem 0;
  border-radius: 0.313rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-tabs .rs-btn {
  margin-left: auto;
}

.update-status-btn {
  background-color: #007bff;
  color: #fff;
  border-radius: 0.313rem;
  padding: 0.313rem 0.625rem;
  cursor: pointer;
}

.completed-step .rs-timeline-item-dot::before {
  background-color: var(--clr--primary) !important;
}

/* Ensure the timeline dot is visible */
.rs-timeline-item-dot::before {
  border-color: var(--clr--primary) !important;
}

.custom-tabs .rs-nav-pills {
  padding: 0.5rem;
}

.orderStatus {
  text-transform: capitalize;
}

.daterange-orders {
  margin: 1.25rem 0 1.25rem;
}

.status-timeline-container {
  background: #fff;
  border-radius: 0.625rem;
}

.order-dets-btn {
  margin: 1.875rem;
  margin-top: 1.25rem;
}

.search-flex {
  display: flex;
  width: 100%;
  background-color: #fff;
}

.order-return {
  margin: 3.125rem 0;
}

.returned-order {
  padding: 0.625rem;
  background-color: #fff;
  border-radius: 0.313rem;
}

.cancel-btns {
  margin-bottom: 0.313rem;
}

.cancel-bth {
  margin-left: 10px;
}

.rs-nav-horizontal {
  display: flex;
  flex-wrap: wrap;
}

.order-summary-address {
  gap: 20px;
}

.order-summary-add {
  display: flex;
  gap: 20px;
  background-color: #fff;
  padding: 1.25rem !important;
  border-radius: 0.625rem;
  margin-top: 1.25rem;
}

.order-summary-ad3d {
  margin-top: 1.25rem;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
  gap: 20px;
  background-color: #fff;
  padding: 1.25rem;
}

.order-summary {
  flex: 1;
  padding-right: 1rem;
}

.order-summary h5 {
  margin-left: -0.3rem;
}

h5 {
  margin: 0.625rem auto;
}

.products-ybale {
  margin-top: 1rem;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.order-address {
  flex: 1;
}

.order-summary h4,
.order-address h4 {
  margin-bottom: 0.5rem;
}

.order-summary p,
.order-address p {
  margin: 0.25rem 0;
}
.order-prod-image {
  width: 100%;
  width: 6.25rem;
  height: 6.25rem;
}

.returned-order-section {
  margin-top: 1.25rem;
}

.order-details-prod {
  margin: 5px 0;
}

.order-addres {
  padding-left: 1.25rem;
}

.userinfo-list {
  margin: 1.25rem auto;
}

.product-image1 {
  height: 3rem;
}

.order-details-left,
.order-details-right {
  background-color: #fff;
  padding: 1.25rem !important;
  border-radius: 0.625rem;
}

.imagecell {
  height: 3rem !important;
}

.timeline-time {
  margin: 0.5rem;
  font-size: 0.875rem;
}

.status-timeline-container .rs-timeline-item-content {
  width: 100%;
}

.status-timeline-container .tine {
  margin-left: 1.25rem;
}

.order-det-head,
.my-order-det-head {
  font-weight: 600;
  color: rgb(117, 115, 115);
}

.rs-row .order-det-body {
  font-weight: 400;
  color: #6c6c6c;
}

.rs-col .order-details-head {
  font-weight: 400;
  color: #6c6c6c;
  text-align: right;
}

.capitalize1 {
  text-transform: capitalize;
}

.order-details-prod .grand {
  color: #a3a1a1;
}

.order-summary-wrapper {
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-bottom: 30px;
  margin-top: 20px;
}

.order-summary,
.order-address {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.order-summary-row {
  margin-bottom: 10px;
}

.order-det-head {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.order-det-body p {
  margin: 0;
  font-size: 14px;
}

.text-right {
  text-align: right;
}

.text-right p {
  text-align: right;
  margin: 0rem;
}

.order-details-prod-1 {
  margin-left: -2px !important;
}

.total-row {
  margin-top: 15px;
  font-weight: bold;
}

.order-address {
  padding-top: 20px;
}

.order-address p {
  margin: 5px 0;
  font-size: 14px;
}

.order-status {
  font-weight: bold;
  text-transform: capitalize;
}

.grabdd {
  display: flex;
  gap: 20px;
  /* margin-right: 85px; */
  justify-content: end;
}

.text-righ2t {
  text-align: right;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .order-summary-wrapper .order-summary,
  .order-summary-wrapper .order-address {
    margin-bottom: 20px;
  }
}

@media (max-width: 600px) {
  .order-summary,
  .order-address {
    background-color: white;
    padding: 0px;
    border-radius: 8px;
  }

  h3 {
    font-size: 20px;
  }
  .cancel-btns {
    display: flex !important;
    flex-direction: column !important;
    gap: 0.625rem !important;
  }
}
