.orders-bg {
  background: var(--clr-bg);
}

.orders-bg .orders-container {
  background-color: #fff;
  border-radius: 0.625rem;
  margin: 3.125em auto;
  float: none;
}

.rs-panel-body h5 {
  margin: 0;
}

.rs-panel-body p {
  margin: 0.25rem 0;
}

.order-prod-image {
  object-fit: cover;
}

.order-details-bg {
  background-color: var(--clr-bg);
}

.order-details-bg .order-details-page {
  float: none;
  margin: 3.125em auto;
  border-radius: 0.625rem;
  padding: 1.25rem;
  background-color: #fff;
}

.user-note-total {
  margin-top: 1.875rem;
}

.total-check {
  text-align: right;
}

.return {
  display: flex;
  flex-direction: row-reverse;
  align-items: end;
  gap: 1.25rem;
}

.returns-policy p,
.returns-policy a {
  font-size: 0.75rem;
  color: var(--clr-text-light);
  text-align: right;
}

.flex12 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.returns-policy a:hover {
  text-decoration: none;
  color: var(--clr-text-light);
  cursor: pointer;
}

.dets-i .rs-message-container {
  padding: 0.8rem 1.2rem;
}

.address-total {
  margin-bottom: 1.875rem;
}

.order-details-prod {
  text-align: right;
}

.order-prod-list {
  margin-bottom: 0.625rem;
}

.back-button {
  padding: 0.625rem 1.563rem;
  background: var(--clr--primary);
  color: white;
  border-radius: 1.563rem;
}

.user-layout-container .rs-pagination-btn.rs-pagination-btn-active {
  border: 1px solid var(--clr--primary) !important;
  color: var(--clr--primary) !important;
  outline: none;
  box-shadow: none;
}

.user-layout-container .rs-pagination-btn.rs-pagination-btn-active:hover,
.user-layout-container .rs-pagination-btn.rs-pagination-btn-active:focus {
  border: 1px solid var(--clr--primary) !important;
  color: var(--clr--primary) !important;
  box-shadow: none;
  outline: none;
}

.rs-btn-ghost {
  border-radius: 25px !important;
  padding: 10px 20px !important;
  font-size: 18px;
}

.rs-btn-ghost svg {
  width: 20px;
  height: 20px;
}

.ad-back-button {
  padding: 0.625rem 1.563rem;
  color: white;
  background-color: #3498ff;
  border-radius: 1.563rem;
}

.rs-btn-icon.rs-btn {
  padding: 5px;
}

.down-button {
  padding: 0.625rem 1.563rem;
  background: #007bff;
  color: white;
  border-radius: 1.563rem;
}

.return-order-button {
  display: flex;
  justify-content: end;
}

.upload-image {
  margin-top: 1.25rem;
}

.requill {
  height: 7.5rem;
  margin-bottom: 4rem;
}

.retuurm {
  font-size: 18px;
  font-weight: bold;
}

.ml-6 {
  margin-left: 2.8rem;
}

.return-mod .rs-input:focus,
.rs-input:hover:not(:disabled) {
  border-color: #75c32c !important;
  outline: 3px solid var(--clr-text-hover-60);
}

@media (max-width: 768px) {
  .order-details-page h3 {
    font-size: 1.3rem;
  }

  .order-details-page h5 {
    font-size: 1rem;
  }

  .rs-btn-ghost {
    padding: 5px 10px !important;
  }
}
